<script>
import Detail from '@/modules/clue/component/detail.vue'
import ReviewFrom from './reviewForm.vue'
import clueDefeatServices from '@/services/clueDefeatServices'

export default {
  components: { ReviewFrom, Detail },
  data() {
    return {
      id: '',
      leadId: '',
      visible: false,
      applyUserRole:'',
      isUnReview:true // true表示未审核
    }
  },
  created() {
    const { id, leadId ,applyUserRole,isUnReview } = this.$route.query
    this.id = id
    this.leadId = leadId
    this.isUnReview = isUnReview.toString().toLowerCase() === 'true'
    this.applyUserRole = applyUserRole
  },
  methods: {
    handlerConfirm(action, done) {
      if (action === 'confirm') {
        this.$refs.fromRef.validate().then(async () => {
          await this.save()
          done()
        }).catch(() => {
          done(false)
        })
      } else {
        done()
      }
    },
    async save() {
      const params = this.$refs.fromRef.getData()
      params.type=2
      params.ids = [this.id]
      const result = await clueDefeatServices.failedApproval(params)
      this.$router.go(-1)
    },
    // 审核
    handlerAudit() {
      console.log('applyUserRole:',this.applyUserRole)
      this.visible = true
    },
  }
}
</script>

<template>
<div v-if="id" class="page">
  <Detail :id="leadId">
    <template v-slot:btns="{data}">
      <div v-if="isUnReview" class="common-footer">
        <van-button round class="btn" type="primary" @click="handlerAudit">{{$t('无效审核')}}</van-button>
      </div>
    </template>
  </Detail>
  <van-dialog v-model="visible" show-cancel-button :before-close="handlerConfirm"
    confirmButtonColor="#B9921A"
    :cancel-button-text="$t('取消')" 
    :confirm-button-text="$t('确认')"
  >
    <ReviewFrom v-if="visible" ref="fromRef" :applyUserRole="applyUserRole"></ReviewFrom>
  </van-dialog>
</div>
</template>

<style scoped lang="less">
.page {
  padding: 16px 16px 80px;
  position: relative;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;

  /deep/ .btns {
    max-width: 500px;
    margin-left: -16px;
    width: 100%;
    display: flex;
    position: fixed;
    z-index: 999;
    bottom: 0px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    justify-content: space-between;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    font-size: 16px;
    color: #0D171A;

    .btn {
      width: 100% !important;
    }
  }
}
</style>
